import data from "../../assets/data.json";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceSection = () => {
  AOS.init({ duration: 2000 });
  return (
    <div className="service-section" id="#services">
      <div className="service-content">
        <h2 data-aos="fade-left">{data.sections.services.title}</h2>
        <p data-aos="fade-left" data-aos-duration="2200" className="text-first">
          {data.sections.services.subTitle}
        </p>
        <div className="card-container">
          {data.sections.services.details.map((e, i) => (
            <div data-aos="fade-left" data-aos-duration={("2400" + (i * 10)).toString} key={i} className="card" >
              <p className="card-title">
                {e.title}
              </p>
              <p className="card-description">
                {e.description}
              </p>
              <div className="subservices-container">
                {e.subServices.map((e, i) => (
                  <p className="card-subservices">
                    • {e}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
