import React from "react";
import "../../styles/sections/clientsSection.scss";
import data from "../../assets/data.json";
import AOS from "aos";
import "aos/dist/aos.css";

const ClientsSection = ({ clients }) => {
  AOS.init({ duration: 2000 });
  return (
    <section className="clients-section">
      <div className="title" data-aos="fade-up">
        <h2>{data.sections.clients.title}</h2>
        <p data-aos="fade-up" data-aos-duration="2200">
          {" "}
          {data.sections.clients.subTitle}
        </p>
      </div>
      <div className="clients-icons">
        {clients.map((client, index) => (
          <a
            href={client.url}
            key={index}
            className="client-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={client.icon} alt={`Client ${index + 1}`} />
          </a>
        ))}
      </div>
    </section>
  );
};

export default ClientsSection;
