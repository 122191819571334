import { useEffect } from "react";
import "../../styles/components/trailEffect.scss";

const TrailEffect = () => {
  useEffect(() => {
    var dots = [],
      mouse = {
        x: 0,
        y: 0,
      };

    var Dot = function () {
      this.x = 0;
      this.y = 0;
      this.node = (function () {
        var n = document.createElement("div");
        n.className = "trail";
        document.body.appendChild(n);
        return n;
      })();
    };

    Dot.prototype.draw = function () {
      this.node.style.left = this.x + "px";
      this.node.style.top = this.y + "px";
    };

    for (var i = 0; i < 12; i++) {
      var d = new Dot();
      dots.push(d);
    }

    const draw = () => {
      var x = mouse.x,
        y = mouse.y;

      dots.forEach(function (dot, index, dots) {
        var nextDot = dots[index + 1] || dots[0];

        dot.x = x;
        dot.y = y;
        dot.draw();
        x += (nextDot.x - dot.x) * 0.6;
        y += (nextDot.y - dot.y) * 0.6;
      });
    };

    const updateMousePosition = (event) => {
      mouse.x = event.pageX;
      mouse.y = event.pageY;
    };

    window.addEventListener("mousemove", updateMousePosition);

    const animate = () => {
      draw();
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
      dots.forEach((dot) => dot.node.remove()); // Cleanup created divs
    };
  }, []);

  return null; // This component does not render anything itself
};

export default TrailEffect;
