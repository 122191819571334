import React from "react";
import Navbar from "../../components/atoms/Navbar";
import HomeSection from "../../components/organisms/HomeSection";
import AboutSection from "../../components/organisms/AboutSection";
import ServiceSection from "../../components/organisms/ServiceSection";
import ClientsSection from "../../components/organisms/ClientsSection";
import ContactSection from "../../components/organisms/ContactSection";
import Footer from "../../components/molecules/Footer";
import TrailEffect from "../../components/atoms/TrailEffect";
import MuroIcon from "../../assets/Muro.svg";
import DiviaIcon from "../../assets/Divia.svg";
import Markovic015Icon from "../../assets/Markovic.svg";
import BozicIcon from "../../assets/Bozic.svg";
import VarIcon from "../../assets/Var.svg";
import BoschIcon from "../../assets/BoschBurin.svg";
import ReactGA from 'react-ga';

class MainPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  }

  render() {
    const clients = [
      { icon: MuroIcon, url: "https://murohotelconsult.com" },
      { icon: DiviaIcon, url: "https://divia-consult.com" },
      { icon: Markovic015Icon, url: "https://www.markovic015.rs/" },
      { icon: BozicIcon, url: "https://www.ogradebozic.com" },
      { icon: VarIcon, url: "https://www.varmontaza.rs" },
      { icon: BoschIcon, url: "https://boschservis-ilijaburin.com/" },
    ];

    ReactGA.send({
      hitType: "pageview",
      page: '/',
      title: 'Home'
    });

    return (
      <div className="main__page">
        <TrailEffect />
        <Navbar />
        <section id="home">
          <HomeSection />
        </section>
        <section id="about">
          <AboutSection />
        </section>
        <section id="service">
          <ServiceSection />
        </section>

        <section id="clients">
          <ClientsSection clients={clients} />
        </section>
        <section id="contact">
          <ContactSection />
        </section>
        <section id="footer">
          <Footer />
        </section>
      </div>
    );
  }
}

export default MainPage;
