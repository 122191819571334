import React, { useState } from "react";
import data from "../../assets/data.json";
import Logo from "../../assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
// import iconOpen from "../../assets/menuOpen.svg";
// import iconClose from "../../assets/menuClose.svg";

const Navbar = () => {
  AOS.init({ duration: 2000 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="navbar" data-aos="fade-down">
      <div className="navbar-container">
        <div className="logo">
          <img alt="Digital marketing Sabac" src={Logo} />
        </div>
        <button
          className={`menu-button ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <div className="menu-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <ul className={`navbar-nav ${isMenuOpen ? "open" : ""}`}>
          {data.navBar.map((e, index) => (
            <li className="nav-item" key={index} onClick={closeMenu}>
              <a href={e.href}>{e.title}</a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
