import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import MainPage from "../../pages/MainPage/MainPage";
import "../../styles/App.scss";
import ReactGA from 'react-ga';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export default function App() {
  ReactGA.initialize('G-9XB8PJEDC6');
  return (
    <BrowserRouter>
      <TrackPageView />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}
