import React, { useState, useEffect } from "react";
import "../../styles/components/footer.scss";
import data from "../../assets/data.json";
import Logo from "../../assets/logo.png";
import emailIcon from "../../assets/emailIcon.svg";
import instaIcon from "../../assets/instaIcon.svg";
import phoneIcon from "../../assets/phoneIcon.svg";
import FooterMobile from "./FooterMobile";

const Footer = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <FooterMobile />
      ) : (
        <div className="footer">
          <div className="container">
            <div className="logo-item">
              <img className="logo-img" alt="Brand" src={Logo}></img>
              {/* <p>
              Original and cutting-edge. Designed with precision to deliver an
              outstanding user experience and top-notch performance.
            </p> */}
            </div>
            <div className="row-items">
              <div className="contact-item">
                <div className="contact-details">
                  <h2>Contact Us</h2>
                  <div className="contact-info">
                    <img src={emailIcon} alt="Email" className="icon-contact" />

                    <a
                      href={`mailto:${data.sections.contact.informations.emailOffice}`}
                    >
                      {data.sections.contact.informations.emailOffice}
                    </a>
                  </div>{" "}
                  <div className="contact-info">
                    <img
                      src={instaIcon}
                      alt="Instagram"
                      className="icon-contact"
                    />

                    <a href={data.sections.contact.informations.instagram}>
                      @vortex-mk
                    </a>
                  </div>
                  <div className="contact-info">
                    <img src={phoneIcon} alt="Phone" className="icon-contact" />

                    <a href={`tel:${data.sections.contact.informations.phone}`}>
                      +38163 8824679
                    </a>
                  </div>
                </div>
              </div>

              <div className="business-hours">
                <h2>Business Hours</h2>
                <p>Monday - Friday: 9:00 AM - 9:00 PM</p>
                <p>Saturday: 10:00 AM - 4:00 PM</p>
                <p>Sunday: Closed</p>
              </div>
              <div className="quick-links">
                <h2>Quick Links</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#about">About Us</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>

                  <li>
                    <a href="#clients">Clients</a>
                  </li>
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>&copy; 2022 Vortex-MK. All rights reserved.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
