import React, { useState } from "react";
import "../../styles/components/footerMobile.scss";
import data from "../../assets/data.json";
import emailIcon from "../../assets/emailIcon.svg";
import instaIcon from "../../assets/instaIcon.svg";
import phoneIcon from "../../assets/phoneIcon.svg";

const FooterMobile = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="footer-mobile">
      <div className="container">
        <div
          className={`contact-details ${
            activeSection === "contact" ? "active" : ""
          }`}
        >
          <h2 onClick={() => toggleSection("contact")}>
            Contact Us{" "}
            <span className="arrow">
              {activeSection === "contact" ? "▲" : "▼"}
            </span>
          </h2>
          <div className="contact-info">
            <img src={emailIcon} alt="Email" className="icon-contact" />
            <a
              href={`mailto:${data.sections.contact.informations.emailOffice}`}
            >
              {data.sections.contact.informations.emailOffice}
            </a>
          </div>
          <div className="contact-info">
            <img src={instaIcon} alt="Instagram" className="icon-contact" />
            <a href={data.sections.contact.informations.instagram}>
              @vortex-mk
            </a>
          </div>
          <div className="contact-info">
            <img src={phoneIcon} alt="Phone" className="icon-contact" />
            <a href={`tel:${data.sections.contact.informations.phone}`}>
              +38163 8824679
            </a>
          </div>
        </div>

        <div
          className={`business-hours ${
            activeSection === "hours" ? "active" : ""
          }`}
        >
          <h2 onClick={() => toggleSection("hours")}>
            Business Hours{" "}
            <span className="arrow">
              {activeSection === "hours" ? "▲" : "▼"}
            </span>
          </h2>
          <div>
            <p>Monday - Friday: 9:00 AM - 9:00 PM</p>
            <p>Saturday: 10:00 AM - 4:00 PM</p>
            <p>Sunday: Closed</p>
          </div>
        </div>

        <div
          className={`quick-links ${activeSection === "links" ? "active" : ""}`}
        >
          <h2 onClick={() => toggleSection("links")}>
            Quick Links{" "}
            <span className="arrow">
              {activeSection === "links" ? "▲" : "▼"}
            </span>
          </h2>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/clients">Clients</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2022 Vortex-MK. All rights reserved.</p>
      </div>
    </div>
  );
};

export default FooterMobile;
