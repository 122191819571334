import "../../styles/sections/contactSection.scss";
import data from "../../assets/data.json";
import ContactForm from "../molecules/ContactForm";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactSection = () => {
  AOS.init({ duration: 2000 });
  return (
    <div className="contact-section">
      <div className="contact-info">
        <h1 data-aos="fade-down">{data.sections.contact.title}</h1>
        <div
          className="contact-wrapper"
          data-aos="fade-up"
          data-aos-duration="2200"
        >
          <div className="form-container">
            <ContactForm />
          </div>
        </div>
      </div>
      {/* <div className="contact-image"></div> */}
    </div>
  );
};

export default ContactSection;
