import React, { useState, useRef } from "react";
import Sphere from "../../assets/sphere.gif";
// import Sphere from "../../assets/ball3.png";
import EmailIcon from "../../assets/emailIcon.png";
import AOS from "aos";
import "aos/dist/aos.css";
import data from "../../assets/data";

const HomeSection = () => {
  AOS.init({ duration: 2000 });
  const sphereRef = useRef(null);
  const [spherePosition, setSpherePosition] = useState({ x: 0, y: 0 });

  // Sphere movement effect
  const handleSphereMouseMove = (event) => {
    const { clientX, clientY } = event;
    const sphere = sphereRef.current;
    const { left, top, width, height } = sphere.getBoundingClientRect();
    const centerX = left + width / 2;
    const centerY = top + height / 2;
    const radius = 50; // Radius of the movement area
    const deltaX = clientX - centerX;
    const deltaY = clientY - centerY;
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    const maxDistance = distance > radius ? radius : distance;

    const angle = Math.atan2(deltaY, deltaX);
    const x = Math.cos(angle) * maxDistance;
    const y = Math.sin(angle) * maxDistance;

    setSpherePosition({ x, y });
  };

  return (
    <div className="home__page">
      <div className="home__container">
        <div className="text-content" data-aos="fade-left">
          <h1>
            {data.sections.homePage.h1.map((e, i) => (
              <h2 key={i} className={i === 2 ? "highlight" : ""}>
                {e}
              </h2>
            ))}
          </h1>
          <p data-aos="fade-left" data-aos-duration="2200">
            {data.sections.homePage.subTitle.map((e, i) => (
              <h2 key={i}>{e}</h2>
            ))}
          </p>
          <div
            className="contact-info"
            data-aos="fade-left"
            data-aos-duration="2400"
          >
            <img src={EmailIcon} alt="Email us" />
            <a
              href={`mailto:${data.sections.contact.informations.emailOffice}`}
            >
              {data.sections.contact.informations.emailOffice}
            </a>
          </div>
        </div>

        <div
          data-aos="fade-right"
          className="sphere-container"
          onMouseMove={handleSphereMouseMove}
          onMouseLeave={() => setSpherePosition({ x: 0, y: 0 })}
          style={{
            position: "relative",
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <img
            ref={sphereRef}
            src={Sphere}
            className="sphere"
            alt="Digital Art"
            style={{
              transition: "transform 0.1s ease-out",
              transform: `translate(${spherePosition.x}px, ${spherePosition.y}px)`,
              height: "250px",
              zIndex: 1,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
