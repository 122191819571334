import React from "react";
// import AOS from "aos";
import "aos/dist/aos.css";
import data from "../../assets/data";
const AboutSection = () => {
  // AOS.init({ duration: 2000 });

  return (
    <div className="about-section">
      <h2>{data.sections.about.title}</h2>

      <div className="about-text-container">
        {data.sections.about.aboutDetails.map((item, index) => (
          <div className={`row ${index % 2 ? 'right' : ''}`} data-aos={`${index % 2 ? 'fade-left' : 'fade-right'}`}
            data-aos-duration={`${index < 1 ? '2400' : '2450'}`} >

            <p className="title">{item.title}</p>
            <p>{item.details}</p>
          </div>
        ))}
      </div>
      <div className="container-blue">
        <p>{data.sections.about.subTitle}</p>
      </div>
    </div>
  );
};

export default AboutSection;
